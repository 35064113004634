<template>
  <VCard actions-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage
          class="mr-2"
          :src="avatar"
          :name="name"
          :width="12"
          :height="12"
        />

        <div>
          <div class="text-base font-medium">
            {{ name }}
          </div>

          <div class="label text-sm font-light">
            {{ username }}
          </div>
        </div>
      </div>
    </template>

    <template #title-actions>
      <VChip
        :text="$t(`app.${request?.status}`)"
        :class="getStatusColor(request?.status)"
      />
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-4">
        <slot name="content" />

        <VLine
          class="col-span-2"
          :label="$t('app.registration_requested_on')"
          :value="formatDate({ date: request.registration_requested_on })"
        />

        <!-- LEARNER COMMENT -->
        <div v-if="request.learner_comments" class="col-span-2">
          <VLine :label="$t('app.learner_comment')" />

          {{ request.learner_comments }}
        </div>

        <slot name="form" />
      </div>
    </template>

    <template #actions>
      <div class="flex justify-end">
        <VButton
          :label="$t('app.reject')"
          :is-loading="isRejecting"
          icon="fal fa-times-circle"
          class="btn-danger btn-sm mr-2"
          @click="onReject"
        />

        <VButton
          :label="$t('app.approve')"
          :is-loading="isApproving"
          icon="fal fa-check-circle"
          class="btn-success btn-sm"
          @click="onApprove"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
// Composables
import useRequest from "@/composables/useRequest";
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
// Components
import VCard from "@/components/VCard";
import VLine from "@/components/VLine";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import VButton from "@/components/VButton";

export default {
  components: {
    VCard,
    VChip,
    VImage,
    VLine,
    VButton
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    },
    endpoint: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    // Composables
    const { isRequesting: isApproving, request: approve } = useRequest();
    const { isRequesting: isRejecting, request: reject } = useRequest();
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { formatDate, getAvatarURL } = useDisplay();

    // Computed
    // eslint-disable-next-line
    const training = computed(() => props.request?.training || {});
    const learner = computed(() => props.request?.learner || {});
    // eslint-disable-next-line
    const avatar = computed(() => getAvatarURL(learner.value?.avatar?.view_path));

    const name = computed(() => {
      const { firstname, lastname } = learner.value;

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });

    const username = computed(() => {
      const { username } = learner.value;

      return username ? `@${username}` : "";
    });

    // Methods
    const onApprove = async () => {
      await approve({
        endpoint: `${props.endpoint}.request-approval`,
        pathParams: { id: props.request.id },
        data: {
          decision: "Approved"
        }
      });

      context.emit("refresh");
    };

    const onReject = async () => {
      await reject({
        endpoint: `${props.endpoint}.request-approval`,
        pathParams: { id: props.request.id },
        data: {
          decision: "Denied"
        }
      });

      context.emit("refresh");
    };

    return {
      name,
      username,
      training,
      avatar,
      onApprove,
      onReject,
      // useRequest
      isApproving,
      isRejecting,
      // useDisplay
      formatDate,
      // useTexts
      getText,
      // useColor
      getStatusColor
    };
  }
};
</script>
